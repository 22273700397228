//colors

$primary: #0995dd;
$secondary: #ff4848;
$third: #030300;

//fonts
$font-primary: 'Source Sans Pro', sans-serif;
$font-secondary: 'Lato', sans-serif;

//header
$header-border-color: rgba($color: black, $alpha: 0.1);
$language-options-background: white;
$language-options-shadow: 0px 0px 15px 6px rgba(0,0,0,0.05);
$language-option-hover-background: rgba($color: black, $alpha: 0.05);
$header-button-color: #4a4a4a;

//header light
$header-light-border-color: rgba($color: white, $alpha: 0.3);
$language-options-light-background: #0d0d0d;
$language-options-light-shadow: 0px 0px 15px 6px rgba(0,0,0,0.2);
$language-option-light-hover-background: rgba($color: white, $alpha: 0.05);
$header-light-button-color: white;

//footer
$footer-disclaimer-background: #0d0d0d;
$footer-copyright-background: #0d0d0d;
$footer-disclaimer-color: rgba($color: white, $alpha: 0.4);
$footer-disclaimer-link-color: rgba($color: white, $alpha: 0.6);
$footer-disclaimer-link-hover-color: white;
$footer-copyright-color: white;
$payment-providers-background: #0d0d0d;


//register
$register-text-color: #0d0c0a;
$register-currency-color: #0d0c0a;
$register-terms-color: #0d0c0a;
$register-arrow-color: #0d0c0a;

// register dark
$register-dark-text-color: white;
$register-dark-currency-color: #0d0c0a;
$register-container-dark-background:rgba($color: black, $alpha: 0.4);


$register-error-color: #ff4848;
$register-error-invalid-color: #ff4848;
$register-error-valid-color: #80af1d;





@media (max-width: 479px){

}

@media (min-width: 480px){
    
}

@media (min-width: 480px) and (max-width: 767px){

}

@media (max-width: 767px){

}


@media (min-width: 768px){
    
}

@media (min-width: 768px) and (max-width: 991px){
    
}

@media (max-width: 991px){

}


@media (min-width: 992px){
    
}

@media (min-width: 992px) and (max-width: 1023px){
    
}


@media (max-width: 1023px){

}


@media (min-width: 1024px){
    
}

@media (min-width: 1024px) and (max-width: 1199px){
    
}

@media (min-width: 1024px) and (max-width: 1199px) and (max-height: 799px){
    
}

@media (min-width: 1024px) and (max-width: 1199px) and (min-height: 800px){
    
}

@media (max-width: 1199px){

}


@media (min-width: 1200px){
    
}

@media (min-width: 1200px) and (max-width: 1399px){
    
}
@media (min-width: 1200px) and (max-width: 1399px) and (max-height: 799px){
    
}

@media (min-width: 1200px) and (max-width: 1399px) and (min-height: 800px){
    
}

@media (max-width: 1399px){

}


@media (min-width: 1600px){
    
}

@media (min-width: 1400px) and (max-height: 799px){
    
}

@media (min-width: 1400px) and (min-height: 800px) and (max-height: 899px){
    
}

@media (min-width: 1400px) and (min-height: 900px){
    
}


// based on rem

@media (max-height: 799px){

}

@media (min-height: 800px) and (max-height: 899px){

}

@media (min-height: 900px){

}

@media (max-width: 1023px){

}

@media (min-width: 1024px) and (max-width: 1399px){

}

@media (min-width: 1400px){

}
