.button,
.button:hover,
.button:active,
.button:focus{
    outline: none !important;
    background-color: transparent;
    border: none;
    font-family: $font-secondary;
    cursor: pointer;
    // transition: all 100ms ease-in-out;
    padding: 20px 30px;
    text-align: left;
    font-size: 1rem;
    width:100%;
    position: relative;
}

.button-full,
.button-full:hover,
.button-full:focus,
.button-full:active
{
    display: flex;
    flex-direction: column;
    color: white;
    background-color: $secondary;
    border-radius: 5px;
    font-weight: 400;
}

.button-full:hover{
    background-color: $secondary;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

.button-full:active{
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
}

.button-full{
    small{
        font-size: 0.8em;
        opacity: 0.6;
    }
}

.button-full:after{
    content:url(../../images/icons/play.png);
    display: block;
    position: absolute;
    right:25px;
    top:50%;
    transform: translateY(-50%);
  }