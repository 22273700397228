html{
    font-size: 18px;
}

body,
html{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
}

body{
    font-family: $font-primary;
    background-color: #f0f0f0;
    color:#000;
}



ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.container-wrapper{
    max-width:320px;
    margin:0 auto;
}

a,
a:hover,
a:active,
a:focus{
    text-decoration: none;
    color: $primary;
    outline: none;
}

h1, 
h2, 
h3, 
h4, 
h5, 
h6{
    font-family: $font-secondary;
}

input{
    outline: none !important;
}

.subtitle{
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #4a4a4a;
    text-align: center;
    font-weight: 600;

    .big{
        font-size: 2.6rem;
        font-weight: 900;
    }
}

p{
    line-height: 1.5;
    font-size: 1.15rem;
    margin: 0;
}

.text-highlight{
    color: $primary;
}
.text-center{
    text-align:center;
}

.flex-grow-1{
    flex-grow: 1;
}


.close-icon{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    padding: 10px;
    box-sizing: content-box;

    div{
        position: relative;
        width: 100%;
        height: 100%;

        &:after,
        &:before{
            content: "";
            position: absolute;
            top: 48%;
            left: 14%;
            transform: translate(-50%,-50%);
            width: 22px;
            height: 3px;
            border-radius: 7px;
            background-color: #fff;
            transform-origin: 50% 50%;
        }

        &:after{
            transform: rotate(45deg);
        }

        &:before{
            transform: rotate(-45deg);
        }
    }

}

.logo{
    text-align: center;
    padding:20px 0;
    img{
        display: inline-block;
        vertical-align: middle;
        max-width: 130px;
    }
}

.disclaimer{
    padding:1rem 0.5rem;
    font-size: 0.7rem;
    color:rgba($color: #000000, $alpha: 0.6);
    text-align: center; 
}

@media (max-width: 767px){

    .subtitle{
        font-size: 1.7rem;
        .big{
            font-size: 2.3rem;
        }
    }

    .no-mobile{
        display: none !important;
    }

}

@media (min-width: 768px){

    .mobile{
        display: none !important;
    }

}

@media (max-width: 991px){

    .no-tablet{
        display: none !important;
    }

}

@media (min-width: 992px){

    .tablet{
        display: none !important;
    }

}

