#register{
    max-width: 320px;
    margin:0 auto;
    background-color: #fff;
    background-image: url("../../images/form-background.png");
    background-repeat: no-repeat;
    padding:1rem;
    border-radius: 5px;
    
    h2{
        width: 100%;
        font-size: 1.7rem;
        font-weight: 600;
        margin-bottom: 3rem;
        color: $register-text-color !important;
    }

    .age{
        font-size: 0.7rem;
        color:#000;
    }

    .form-group{
        margin: 0;
        position: relative;
        margin-bottom: 0.7rem;
        display: flex;
        flex-direction: column;
    }

    .form-group-container{
        display: flex;

        .form-group{
            &:first-child:not(:last-child){
                margin-right: 1rem;
            }
        }
    }

    .buttongroup{
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;

        button{
            width: 50%;
            background: transparent;
            border: 1px solid #dfdfe7;
            color: #000000;
            font-family: inherit;
            outline:none;
            text-transform: uppercase;
            font-size: 0.8rem;
            cursor: pointer;
            display: block;
            padding:0.6rem;
            outline: none;

            &:first-child{
                border-radius: 5px 0 0 5px;
            }
            &:last-child{
                border-radius:0 5px 5px 0;
                border-left:none;
            }
            &.selected{
                background: $primary;
                border-color: $primary;
                color:#fff;
            }
        }
    
    }

    .show-password{
        outline: none !important;
        cursor: pointer;
        background: none;
        border: none;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.6rem;
        width: 3rem;
        opacity: 0.3;

        &.visible{
            opacity: 1;
        }
    }

    .flag-container{
        outline: none !important;
        height: 100%;
        max-height: 3rem;
    }

    .selected-flag{
        outline: none !important;
        position: relative;
        top: 3px !important
    }

    .password-input-container{
        margin-bottom: 0.5rem;
    }

    input:not([type="checkbox"]){
        width: 100%;
    }


    input{
        height: 2.6rem;
        font-size: 1rem;
        display: flex;
        align-items: center;
        transition: border 300ms ease;
        color: #000;
        margin-bottom: 0.4rem;
        border:none;
        background: transparent;
        border-bottom: 1px solid #eaeaec;
        font-family:$font-primary;
        font-weight: 300;

        &:focus{
            
        }
        &::placeholder{
            color: #000;
            opacity: 1;
        }
        &.error{
            color:$secondary;

            &::placeholder{
                color:$secondary;
            }
        }
    }

    input.phone{
        margin-bottom: 0.5rem !important;
        margin-top: 0.3rem !important;
    }

    // #password{
    //     margin-bottom: 0rem;
    // }

    .rules{
        padding-top:10px;
        li{
            font-size: 0.7rem;
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 0.5rem;
            position: relative;
            transition: all .15s ease-in-out;

            i{
                margin-right: 7px;
                font-size: 0.7rem;

                &:before{
                    content: "\f00c";
                    font-size: 0.8em;
                }
            }

            &.valid{
                color: $register-error-valid-color;
            }

            &.invalid{
                color: $register-error-invalid-color;
                i:before{
                    opacity: 0;
                }
            }
        }
    }

    label.error{
        font-size: 0.7rem;
        display: flex;
        align-items: center;
        color: $register-error-color;
        width: 100%;
        position: relative;
        transition: all .15s ease-in-out;

        img{
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-right: 1rem;
            visibility: hidden;
            opacity: 0;
            transition: all .15s ease-in-out;
        }
    }

    label.error[for=password]{
        color: white;

        &.error-correct{
            color: $primary;
            img{
                visibility: visible;
                opacity: 1;
            }
        }
    }

    label.error[for=accept_terms] {
        text-align: center;
        position: absolute;
        display: flex;
        bottom: 3px;
        margin: 0 !important;
        font-size: 0.7rem;
        padding:0;
    }

    .currency-container{
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .currency-label,
        .selected-currency,
        .currency-list label{
            color: $register-text-color;
            font-family: $font-primary;
            font-size: 1rem;
            font-weight: 700;
            text-transform: uppercase;
        }

        .currency-label{
            margin-right: 12px;
        }

        .currency-select{

            position: relative;

            .selected-currency{
                position: relative;
                z-index: 4;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 7px 12px;
                cursor: pointer;
                transition: background-color .15s ease-in-out;

                span{
                    width: 2.5rem;
                    display: flex;
                    align-items: center;
                }

                img{
                    margin-right: 15px;
                }

                svg{
                    height: 8px;
                    width: auto;
                    fill: $register-arrow-color;
                }

                &:hover{
                    background-color: rgba(255,255,255,0.1);
                }
            }
    
            .currency-list{

                position: absolute;
                z-index: 3;
                top: 100%;
                left: 0;
                background-color: #fff;
                box-sizing: border-box;
                overflow: hidden;
                flex-direction: column;
                transform: translateY(-50%);
                left: 100%;
                opacity: 0;
                visibility: hidden;

                li{
                    box-sizing: border-box;

                    label{
                        width: 100%;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        user-select: none;
                        position: relative;
                        box-sizing: border-box;
                        padding: 7px 12px;
                        color: $register-currency-color;

                        input{
                            position: absolute;
                            left: 0;
                            top: 0;
                            opacity: 0;
                            cursor: pointer;
                            width: 100%;
                            height: 100%;
                            margin: 0;
                        }

                        img{
                            margin-left: 15px;
                        }

                    }

                    &:hover{
                        background-color: #f7f7f7;
                    }
                }
            }

            &:hover{
                .currency-list{
                    display: flex;
                }
            }
        }

        img{
            height: 15px;
            width: auto;
        }
    }

    .form-check-label{
        display: flex;
        margin-bottom: 30px;
        color: rgba($color: $register-text-color, $alpha: 1);
        cursor: pointer;

        a{
            color:$secondary;
            text-decoration: underline;
        }

        input[type=checkbox]{
            position: absolute;
            top: 0;
            left: 0;
            visibility: hidden;
        }

        .custom-checkbox{
            position: relative;
            display: flex;
            min-width: 15px;
            height: 15px;
            margin-right: 15px;
            border-radius: 3px;
            background-color: #ffffff;
            box-sizing: content-box;
            border: solid 1px rgba(0,0,0,0.1);
            cursor: pointer;

            &:after{
                content: "";
                position: absolute;
                width: 11px;
                height: 11px;
                left: 2px;
                top: 2px;
                border-radius: 3px;
                background-color: $primary;
                opacity: 0;
                transition: all .1s ease-in-out;
            }
        }

        input[type=checkbox]:checked + .custom-checkbox:after{
            opacity: 1;
        }
    }

    .disclaimer{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

}

.register-container{
    box-sizing: border-box;
    padding: 2rem;
    border-radius: 20px;
    width: 420px;
}



.es #register .buttongroup button{
    padding: 0.6rem 0.2rem;
    font-size: 0.75rem;
}